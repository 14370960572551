
import { computed, defineComponent, onMounted, ref } from 'vue'
import { setCurrentPageTitle, setCurrentPageTitleButton } from '@/core/helpers/breadcrumb'
import { getEngagementColor, getConfidenceColorAndLabel, getSkillColor } from '@/core/mc-admin-helpers/statistics'
import { useRouter } from 'vue-router'
import { Actions, Mutations } from '@/store/enums/StoreEnums'
import { useStore } from 'vuex'
// import { Employee } from '@/store/modules/EmployeeModule'
import TheEmployeeActionsDropdown from '@/views/mc-admin-employees/TheEmployeeActionsDropdown.vue'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { MetricsPayload } from '@/store/modules/StatisticModule'
import TheEditEmployeeForm from '@/views/mc-admin-employees/TheEditEmployeeForm.vue'
import { MenuComponent } from '@/assets/ts/components'

export default defineComponent({
  name: 'employee',
  components: {
    TheEmployeeActionsDropdown,
    TheEditEmployeeForm

  },
  setup () {
    const store = useStore()
    const router = useRouter()
    const userUuid = router.currentRoute.value.params.id
    const averageStatistics = ref(computed(() => store.getters.getEmployeeStatisticsForSessionsAverage))
    const modalEditEmployeeVisible = ref(false)
    const employeeToEdit = ref({}) as any
    const getRandomFloat = (min, max, decimals) => {
      const str = (Math.random() * (max - min) + min).toFixed(decimals)
      return parseFloat(str)
    }
    const engagement = getRandomFloat(1.4, 5.8, 2)
    const confidence = Math.random() * 50
    const skillLevel = Math.random() * 50
    const employee = computed(() => store.getters.getEmployee)
    onMounted(() => {
      store.dispatch(Actions.API_GET_EMPLOYEE, userUuid).then((response) => {
        // employee.value = response.payload
        store.commit(Mutations.SET_EMPLOYEE, response.payload)
        MenuComponent.reinitialization()
        employeeToEdit.value = response.payload
      })
      const filter: MetricsPayload = {
        metrics: ['employee_statistics_for_sessions_average'],
        filter: {
          employees: [userUuid],
          status: 4
        }
      }
      store.dispatch(Actions.API_GET_METRICS_BY_FILTER, filter)
    })
    // const employee = computed(() => store.getters.getEmployee) as any

    const onEditEmployeeSuccess = (updatedEmployee) => {
      store.commit(Mutations.SET_EMPLOYEE, updatedEmployee)
      modalEditEmployeeVisible.value = false
      employeeToEdit.value = updatedEmployee
    }
    const onDeleteAction = () => {
      store.dispatch(Actions.API_DELETE_EMPLOYEE, userUuid).then(() => {
        router.push('/employees')
      }).catch((response) => {
        Swal.fire({
          text: response.data.error.message,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Try again',
          customClass: {
            confirmButton: 'btn fw-bold btn-light-danger'
          }
        })
      })
    }

    onMounted(() => {
      setCurrentPageTitle('Employee details')
      setCurrentPageTitleButton({})
    })

    return {
      userUuid,
      employee,
      engagement,
      confidence,
      skillLevel,
      averageStatistics,
      modalEditEmployeeVisible,
      employeeToEdit,
      onDeleteAction,
      onEditEmployeeSuccess,
      getEngagementColor,
      getConfidenceColorAndLabel,
      getSkillColor
    }
  }
})
