
import { defineComponent, onMounted } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'employee-actions-dropdown',
  emit: ['remove-employee'],
  components: {},
  setup () {
    const route = useRoute()

    onMounted(() => {
      console.log('route', route.params.id)
    })
  }
})
